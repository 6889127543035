.pass-container{
    position: relative;
}

.pass-container i{    
    margin-left: -30px;
    cursor:pointer
}

.pass-container i:hover{
    color: grey;
  cursor: pointer;
}
.text-md {
    font-size: 1em;
}
.bu-sm {    
    padding: 6px 10px;
    min-width: 75px;
    font-size: 0.89em;
}
.bg-white{
    background-color: #fff;
}
.show-block{
    display: block;
}
.float-none {
    float: none;
}
.img-fluid {
    max-width: 100%;
    height: auto;
}
.text-green{
    color: seagreen;
}
.text-blue{
    color: #1a73e8;
}
.text-warning{
    color: #ce861a;
}
.text-red{
    color: #f05050;
}
.text-gray {
    color: gray;
}
.w-25 {
    width: 25% !important;
  }
  
  .w-50 {
    width: 50% !important;
  }
  
  .w-75 {
    width: 75% !important;
  }
  
  .w-100 {
    width: 100% !important;
  }
  
  .w-auto {
    width: auto !important;
  }
  
  .h-25 {
    height: 25% !important;
  }
  
  .h-50 {
    height: 50% !important;
  }
  
  .h-75 {
    height: 75% !important;
  }
  
  .h-100 {
    height: 100% !important;
  }
  
  .h-auto {
    height: auto !important;
  }
  
  .mw-100 {
    max-width: 100% !important;
  }
  
  .mh-100 {
    max-height: 100% !important;
  }
  
  .min-vw-100 {
    min-width: 100vw !important;
  }
  
  .min-vh-100 {
    min-height: 100vh !important;
  }
  
  .vw-100 {
    width: 100vw !important;
  }
  
  .vh-100 {
    height: 100vh !important;
  }
  
.d-flex{
    display: flex;
}
.d-block {
    display: block;
}
.d-inline-block {
    display: inline-block;
}
.d-inline {
    display: inline;
}
.flex-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
}
.flex-wrap{
    flex-wrap: wrap;
}
  .flex-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
.align-items-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  
  .align-items-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  
  .align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  
  .align-items-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  
  .align-items-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  
  .align-content-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  
  .align-content-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  
  .align-content-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  
  .align-content-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  
  .align-content-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  
  .align-content-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  
  .align-self-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  
  .align-self-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  
  .align-self-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  
  .align-self-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  
  .align-self-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  
  .align-self-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
.justify-center{
    justify-content: center !important;
}
.justify-between{
    justify-content: space-between !important;
}
.justify-end{
    justify-content: end !important;
}
.align-center{
    align-items: center !important;
}
.text-center{
    text-align: center !important;
}
.text-left{
    text-align: left !important;
}
.text-right{
    text-align: right !important;
}
.nav-dropdown {
    top:60px;
}
.border {
    border: 1px solid #ddd !important;
  }
.br-b {
    border-bottom:1px solid #ddd;
}
.br-t {
    border-top:1px solid #ddd;
}
.br-r{
    border-right:1px solid #ddd;
}
.br-l{
    border-left:1px solid #ddd;
}
.br-none{
    border:none;
}
/* .m-0 {
    margin: 0 !important;
}

.mt-0,
.my-0 {
    margin-top: 0 !important;
}

.mr-0,
.mx-0 {
    margin-right: 0 !important;
}

.mb-0,
.my-0 {
    margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
    margin-left: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.mt-1,
.my-1 {
    margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
    margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
    margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
    margin-left: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.mt-2,
.my-2 {
    margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
    margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
    margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
    margin-left: 0.5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.mt-3,
.my-3 {
    margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
    margin-right: 1rem !important;
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
    margin-left: 1rem !important;
} */

/* .m-4 {
    margin: 1.5rem !important;
}

.mt-4,
.my-4 {
    margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
    margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
    margin-left: 1.5rem !important;
}

.m-5 {
    margin: 3rem !important;
}

.mt-5,
.my-5 {
    margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
    margin-right: 3rem !important;
}

.mb-5,
.my-5 {
    margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
    margin-left: 3rem !important;
}  
.p-0 {
    padding: 0 !important;
}

.pt-0,
.py-0 {
    padding-top: 0 !important;
}

.pr-0,
.px-0 {
    padding-right: 0 !important;
}

.pb-0,
.py-0 {
    padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
    padding-left: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.pt-1,
.py-1 {
    padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
    padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
    padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
    padding-left: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.pt-2,
.py-2 {
    padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
    padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
    padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
    padding-left: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.pt-3,
.py-3 {
    padding-top: 1rem !important;
}

.pr-3,
.px-3 {
padding-right: 1rem !important;
}

.pb-3,
.py-3 {
padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
padding-left: 1rem !important;
}

.p-4 {
padding: 1.5rem !important;
}

.pt-4,
.py-4 {
padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
padding-left: 1.5rem !important;
}

.p-5 {
padding: 3rem !important;
}

.pt-5,
.py-5 {
padding-top: 3rem !important;
}

.pr-5,
.px-5 {
padding-right: 3rem !important;
}

.pb-5,
.py-5 {
padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
padding-left: 3rem !important;
} */

/* .mb-1{
    margin-bottom:0.65em !important;
}
.mb-2{
    margin-bottom:0.85em !important;
}
.mb-3{
    margin-bottom:1em !important;
}
.mr-1{
    margin-right:0.65em !important;
}
.mr-2{
    margin-right:0.85em !important;
}
.mr-3{
    margin-right:1em !important;
}
.ml-1{
    margin-left:0.65em !important;
}
.ml-2{
    margin-left:0.85em !important;
}
.ml-3{
    margin-left:1em !important;
} */
.img-16 {
    width: 16px;
    height: 16px;
}
.img-24 {
    width: 24px;
    height: 24px;
}
.img-32 {
    width: 32px;
    height: 32px;
}
main {
    padding-top: 60px;
}
.app-logo {
    max-width:140px;
}
.opt-menu-links {   
    top: 10px;
}
.main-container {
    padding: 0 30px;
}
.app-heading {
    background-color: #fff;
    max-height: 80px;    
    padding: 10px 30px;    
    border-bottom: 1px solid #ddd;
}

.heading-wrap{    
    align-items: center;
    display: flex;    
    justify-content:space-between; 
    font-size: 0.90em;   
}
.heading-wrap select {
    max-width:125px;
}
.heading-wrap h4 {
    font-weight: 600;
    font-size: 1.1em;
}
.action-controls {
    display: inline-flex;
    gap:12px;
}
.main-container {
    background: #f2f2f2;
    padding: 10px;
    min-height: calc(100vh - 60px);
}
.auth_container{
    padding-top:0;    
}
.auth_container .main-container {
    min-height: calc(100vh - 0px);  
}

.form-wrap {
    margin:3em 0;
}
.form-box {
    margin:0 auto;
    max-width: 65%;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    border-radius: 4px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
}
.form-bg-image {        
    background-size: auto;
    background-position: center !important;
    background-size: cover;
    background-repeat: no-repeat !important;
    background-color: #EFF2F7;
    /* background-size: 100% !important; */
}
.form-bg-image.agent-register {
    background: url(../../../images/ta-register-bg.jpg);  
    background-position: center center !important;          
}
.form-bg-image.agent-login {
    background: url(../../../images/ta-login-bg.jpg); 
    background-size: cover;
    background-position: center center !important;    
}
.form-bg-image.forgot-pwd{
    background: url(../images/forgot_password_bg.svg); 
    background-size: 110% !important;  
    background-position: bottom center !important;      
}
.img-holder,.form-holder {
    flex: 0 0 50%;
    max-width: 50%;
    
}
.img-holder {
    min-height: 50vh;;
}
.form-holder {
    background-color: #fff;
}
.form-holder .stay-details {
    max-width: 100%;
    padding: 2em 2em 0em 2em;
}

.login-header {
    margin-bottom: 1.4em;;
}
.login-header .app-logo{
    margin-bottom: 1em;
}
.font-medium {
    font-weight: 500;
}
.font-bold {
    font-weight: bold;
}

.register-options  a {
    margin-bottom: 1em;
}
.bu-fb {
    background-color:#3B5999 ;
    color: #fff;
}
.bu-gp {
    background-color: #6db1ea;
    color: #fff;    
}
.bu-wc {
    background-color:#1CD24D ;
    color: #fff ;
}
.form-holder .social-links a {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 5px;
    padding: 12px 5px 8px 5px;
    font-size: 15px;
}
.form-holder .social-links a i, .form-holder .social-links a span {
    display: block;
    margin-bottom:5px;
}
.phone-code {
    display: flex;
}
.phone-code .c-code{
    flex: 0 0 38%;
    max-width: 38%;
    margin-right:2%;
}
.phone-code .ph-no{
    flex: 0 0 60%;
    max-width: 60%;
}
.divider {    
    height: 1px;
    background-color: #e9e9e9;
    position: relative;    
    margin-bottom:2em 0px;
}
.divider span {
    padding: 5px 20px;
    font-size:14px;
    text-align: center;
    position: absolute;
    top: -14px;
    left: 50%;
    transform: translate(-50%);
    background: #fff;
} 
.opt-menu-links .divider {
    padding: 0px;
    margin:0.1em 0;
}
.register-form form{
    margin-top:1em;
    margin-bottom:2em;
}
.usertype-box {
    display: none;
}
.usertype-box.show {
    display: flex !important;
}
.profile-container {    
    padding: 20px;
}
.profile-image {
    background-color: #fff;
    border-radius: 50%;
    border:2px solid rgba(0,0,0,0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 50px;
}
.profile-image img{
    width:50px;
    height: 50px;
}
.profile-basic p{
    margin:0px !important;
}
.profile-container dt {
    min-width:25%;
}
.comp-logo img {
    max-width:150px;
    height: auto;
}
.form textarea {
    width: 100%;
    background: #fff;
    padding: 5px;
    border: 1px solid #999;
    border-radius: 3px;
}
.p-list {
    margin-right: -10px;    
    margin-left: -10px;
}
.showmore a {
    color:#000;
    text-decoration: none;
}
.showmore a:hover {
    box-shadow: 0px 0 200px rgba(0, 0, 0, 0.25) inset;
}

.opt-menu-links li a {
    display: flex;
}
.banners .p-list > li{
    padding: 10px;
    max-width: 250px;
    min-width: 200px !important;
}
.banner-img {
    margin-bottom:0.5em;
}
.banner-img > img {
    max-width: 100%;
    height: auto;
}
.banners .p-heading h2 {
    font-size: 1em;
}
.popup{
    z-index: 10000;
}
.popup.popup-sm{
    max-width:250px;
}
.popup-container .cancel-btn {
    position: absolute;
    top: -15px;
    right: -20px;
    min-width: 40px;
}
.share-list{
    list-style: none;
    margin:0;
    padding: 0;
}
.share-list li a{
    display: block;
    padding:10px;
    border-bottom:1px solid #ddd;
    text-decoration: none;
    color:#000;
    font-size:0.89em;
}
.share-list li a:hover{
    background-color: rgba(0,0,0,0.05);
}
.app-heading .bubble{
    padding: 0.2em 0.6em 0.3em;
    float: none;
    min-width: 20px;    
}
.shop-list .p-list > li{
    padding: 10px;
    max-width: 250px;
    min-width: 250px !important;
}
.shop-list .p-list > li p {
    font-size: 0.70em;
}
/* .pop-banner-img {
    text-align: center; 
} */
.pop-banner-img > img {
    display: inline-block;
    max-width: 75%;
}
.shop-popup{
    overflow: auto;
    padding: 0;
}
.popup-wrap {
    padding: 20px;
}
.popup-wrap p{
    font-size: 0.85em;
    margin:0;
}
.text-sm {
    font-size: 0.85em;
}
.book-package {
    align-items: flex-start;
}
.detailed-package .t-tabs {
    background: transparent;
}
.detailed-package .t-tabs a{
    line-height: 1rem;
    font-weight: 600;
    text-transform: capitalize;
    display: flex;
    padding: 0px 15px;
    cursor: pointer;
    text-decoration: none;
    color: #666;
    justify-content: center;
    position: relative;
    height: 36px;
    align-items: center;
    border-right: 1px solid #ddd;
    white-space: normal;
    font-size: 0.85em;
}
.check-in-date span {
    width:100%;
}
/* #promocode-input, #findus-other-input,#nNights {display:none;} */
.popup-container {
    display: none;
}
.popup-dimmer {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.25);
}
.popup {
    position: fixed;
    max-width: 400px;
    left: 50%;
    top: 50%;
    width: calc(100% - 20px);
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.popup-footer {
    padding: 10px 0 0;
    display: flex;
    justify-content: space-between;
}
.popup-warning {
    padding: 10px;
    background: #ffffe6;
    border-radius: 5px;
}
.popup-message {
    padding: 10px;
    font-weight: bold;
}
.price-summary {
    color:#fff;
    font-size:1em;
    padding: 0.70em;
    cursor: pointer;
}
.price-summary-popup, .roominfo-popupwrapper {
    overflow-x: hidden;
    overflow-y: auto;
}
.price-summary-popup .popup, .roominfo-popupwrapper .popup {
    padding: 0px;
    min-width: 320px;
    max-width: 650px;
    width: 100%;
   /* height: 100%;*/
    overflow-x: hidden;
    overflow-y: auto; 
    border-radius: 0px;    
}
.roominfo-popupwrapper .popup {
    max-width:950px;
}
.price-summary-popup .popup-header, .roominfo-popupwrapper .popup-header{
    background: #222;
    color:#fff;
    padding: 1em 1.2em;
}
.price-summary-popup #cancelP, .roominfo-popupwrapper #cancelRP{
    font-size: 1em;
    padding:6px 10px;
    text-decoration: none;
    color:#fff;
    border:1px solid rgba(255,255,255,0.3);
    border-radius: 3px;
}
.price-summary-popup #cancelP:hover, .roominfo-popupwrapper #cancelRP:hover{
    background-color: rgba(255,255,255,0.3);
}
.price-summary-popup .room-info {
    font-size: 0.85em;
}
.price-summary-popup .room-price-box li {
    border-bottom: 1px solid #eee;
    padding: 5px 0;
}

.price-summary-popup .booking-details li {
    padding-bottom: 10px;
}
a.today {
    background-color: transparent;
    border: 1px solid #dadce0;
    border-radius: 4px;
    height: 30px;
    display: flex;
    align-items: center;    
    font-weight: 500;
    padding: 0 20px;
    cursor: pointer;
    color: #3c4043;
    text-decoration: none;
}
.todayview-container {
    padding:0px 20px;
}
.todayview-container .header  {
    padding:20px 0px 10px 0;
}
.todayview-container .room-name {
    font-size: 1.2em;
    font-weight: bold;
}
.todayview-container .room-name span {
    font-size: 0.7em;
    font-weight: normal;
    color: #555;
}
.todayview-container .rooms-wrap{
    margin:0px -10px;
    position: relative;
}
.todayview-container .rooms-box .rooms {
    position: relative;
    margin: 5px 5px 30px 5px;    
    background: #fff;
    padding: 10px 5px;
    flex: 1 110px;
    max-width: 110px;
    /* width: calc(140px - 20px); */
    font-size: 0.80em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 6px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
    text-align: center;
    border-top:5px solid #fff;
}
.todayview-container .rooms-box .rooms:hover {
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
}
.todayview-container .rooms-box .rooms a {
    text-decoration: none;
}
.todayview-container .bed-type {
    padding: 5px 0px 5px 0px;
    color: #676264;
    font-size:0.9em;
}

.todayview-container .room-no {
    font-size: 1.5em;
    font-weight: bold;
    color: #000;
    letter-spacing: -0.04em;
    margin-bottom: 5px
}
.todayview-container .floor-name {
    font-size: 0.92em;
    color: #888;
    margin-bottom: 5px;
}
.todayview-container .customer-details{
    font-size: 0.9em;
    color: #888;
    margin-bottom: 10px;    
}
.todayview-container .room-status span{
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%,100%);
    font-size: 1em;    
    padding: 5px 10px;
    border-radius: 10px;
    background-color: #fff;
    color:#000;    
}
.todayview-container .occupy-status{
    position: absolute;     
    /* width:100%; 
    min-width:35%;  
    max-width:90%; */
    left:50%;
    top: -12px;
    transform: translate(-50%,0);
    font-size: 0.8em;    
    padding: 5px 10px;
    border-radius: 10px;
    background-color: #fff;
    color:#000;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
}
.todayview-container .occupy-status span{
    display: flex;
    align-items: center;
    justify-content: center;
}
.todayview-container .rooms.booked {
    border-top-color:#059669;
}
.todayview-container .rooms.hold {
    border-top-color:#1a73e8;
}
.todayview-container .rooms.blocked {
    border-top-color:#EF4444;
}
.todayview-container .rooms.maintenance {
    border-top-color:#ce861a;
}
.todayview-container .rooms.vaccant {
    border-top-color:#6B7280;
}
.todayview-container .rooms.booked .room-status span {
    background-color : #059669;
    color: #fff;
}
.todayview-container .rooms.hold .room-status span {
    background-color:#1a73e8;
    color: #fff;
}
.todayview-container .rooms.blocked .room-status span {
    background-color:#EF4444;
    color: #fff;
}
.todayview-container .rooms.blocked .iconify {
    font-size: 2em;
    color: #EF4444;
}
.todayview-container .rooms.maintenance .room-status span {
    background-color:#ce861a;
    color: #fff;
}

.todayview-container .rooms.vaccant .room-status span {
    background-color:#6B7280;
    color: #fff;
}

.todayview-container span i{
    width:10px;
    height: 10px;
    margin: 0 3px;
    border-radius: 50%;
    border:1px solid #fff;
}
.todayview-container .checkin {
    background-color: rgb(41, 5, 199); 
    border-color: rgb(41, 5, 199) !important;    
}
.todayview-container .bb-checkin  {
    background-color: #000;  
    border-color: #000 !important;    
}
.todayview-container .occupied {
    background-color: rgb(255, 0, 242);    
    border-color: rgb(255, 0, 242) !important;  
}
.todayview-container .checkout {
    background-color: rgba(5, 244, 233, 0.62);
    border-color: rgba(5, 244, 233, 0.62) !important;   
}
.todayview-container .noshow {
    background-color: rgb(230, 255, 0);
    border-color: rgba(0,0,0,0.3) !important; 
}
.todayview-container .fullpay {
    background-color: seagreen;
    border-color:seagreen !important; 
}
.todayview-container .partpay {
    background-color: red;
    border-color:red !important; 
}
.todayview-container .proforma-invoice {
    background-color: rgb(255, 153, 0);
    border-color : rgb(255, 153, 0) !important; 
}

.c-m-dates.c-w-dates > div.booking-bubble i {
	background: black;
	border-radius: 50%;
	margin: 10px;
	height: 10px;
	width: 10px;
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	transform: scale(1);
	animation: pulse 2s infinite;
}
.avilable-rooms .image-holder{
    position: relative;
}
.roominfo{
    position: absolute;    
    width: 28px;
    height: 28px;
    right: 5px;
    top: 5px;
}
.roominfo-toggle {    
    background: rgba(0,0,0,0.65);    
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f7f7f7;
    cursor: pointer;
    padding: 2px;
}
.roompopup-info {
    font-size: 0.89em;
}
.roompopup-info .col-left {
    width:65%;
}
.roompopup-info .col-right {
    width:35%;
}
.roompopup-info h4 {
    font-size: 1.2em;
    font-weight: bold;
}
.roompopup-info .facilities-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
}
.roompopup-info .facilities-list li {
    width: 25%;
}

.roominfo-toggle svg {
    font-size: 24px;
    opacity: 0.75;
}


@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

@media screen and (max-width: 800px) {
    .t-tabs {
        max-width:100%;
    }
    .t-tabs::-webkit-scrollbar {
        display: none;
        width: 0px;
        background: transparent;
    }
    .t-tabs {
        overflow-x: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    
}

.agen-login .inner{
    background-color: #f2f2f2;
    padding:1em 2em;
}
.agen-login h4{
    margin-bottom:1em;
    font-weight: bold;
}
.agentlogin-links {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.agentlogin-links a{
    font-size:13px;
}
.search-box .form-group { 
    display: flex;
    align-items: center;
    justify-content: center;
    margin:0px 20px;
    border:1px solid #cdcdcd;
    padding:5px;
    border-radius: 4px;
}

.search-box .form-group input{ 
    border: none;
    padding:0px 5px;
}
.search-box .form-group input:focus,.search-box .form-group input:focus-visible{ 
    border: none;
    box-shadow: none;
    outline: none;
}
.room-status.col-3 > * {
    width: calc(33.333% - 10px );
}
.paytoresort li {    
    background: #fff;
    margin: 5px 0;
    padding: 10px 10px !important;
    border-radius: 5px;
    border: 1px solid #eee;
    justify-content: space-between;
    color: #666;
    align-items: center;
}
.paytoresort li a {
    text-decoration: none;
}
.expired-invoices a:hover {
    box-shadow: 0 0 28px rgba(0, 0, 0, 0.1);
}
.upcoming-booking .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
}
.task .schedule-list {
    padding:10px 10px;
}
.task-title b{
    font-size:16px;
}
.promo-slider {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content:center;
    text-align: center;
}
.promo-slider .slick-track {
    display: flex;
}
.promo-slider .slick-slide {
    height:auto;
    width:200px;
    padding:20px;
    background-color: #fff;
    margin: 5px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #eee;
    min-height:265px;
}
.promo-slider .slick-slide img {
    max-width: 100%;
    height: auto;
}
.promo-slider .slick-slide:hover {
    box-shadow: 0 0 28px rgba(0, 0, 0, 0.1);
}
.promo-slider .slick-slide a {
    text-decoration: none;
}
.promo-slider .slick-slide h4 {
    color:#000;
    padding:10px 0px 0px;
    font-size: 0.9rem;
    font-weight: 500;    
}

.bill-info-wrap {
    padding: 50px 30px 30px 30px
}
.bill-info-wrap .inner-box {
    max-width:800px;
}
.login-check {
    display: flex;
    padding: 0px 20px 0px 20px;    
}
.login-check .form-option{
    padding:15px 20px;
}

.book-someone, .addPersonintoroom, .addPersonintoroomButtons, .roomlist-header,
.switchtootheroom
{
    display: none;
}
.login-check a{
    color:#1a73e8;
}
.boat-label {
    background-color: #d9d9d9;
    color: #333;
    font-size: 10px;
    padding: 2px 5px;
    font-weight: bold;
    margin: 0px 10px;
    border-radius: 4px;    
    height: 15px;
}
.payment-methods {   
    margin:30px 0px;
    padding:20px;
    background-color: #f3f4f4;       
}
.payment-methods h4 {
    font-weight: 600;
    margin-right:30px;
}

.payment-options a {
    font-size: 12px;
    padding: 6px 10px;
    color: #333;
    background-color: #fff;
    text-decoration: none;
    text-align: center;
    margin: 0px 5px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    font-weight: bold;
    box-shadow:0px 1px 2px rgba(0, 0, 0, 0.3);
    position: relative;
    display: flex;
    align-items: center;
    
}
.payment-options a:hover,.payment-options a.active {    
    color: #1a73e8;    
    border: 1px solid #1a73e8;    
}
.payment-options a.active::before{
    content: "";
    background-image: url('/images/checked-icon.svg');
    width:20px;
    height:20px;
    display: inline-block;    
    background-size: 20px;
    position: relative;    
}
.online-pay, .online-wallet {
    margin-top: 20px;
    display: none;
}
.online-pay .form-option,
.online-pay .form-option label {
    display: flex;
    align-items: center;
}
.price-class {
    flex: 1;
}

.booking-history-table,
.payment-history-table {
    margin:40px 0px;
}
a.opt-menu-title{
    text-decoration: none;
}

.myaccount-wrap {
    display: flex;
    /* height: calc(100vh - 60px); */
}
.myaccount-info-wrap {
    width: calc(100% - 370px);
    background-color: #fff;
}
.myaccount-info-wrap .booking-details-page {
    max-width: 100%;
}
.myaccount-info-wrap .booking-details-page .t-tabs {
    padding-top:10px;
} 
.myaccount-info-wrap .booking-details-page .t-item {
    padding:0px 30px;    
}
.credit-box {
    max-width:400px;
    padding: 20px 30px 30px 30px;
    background: #f3f4f4;
    /* margin: 10px 0px; */
    border-top: 1px solid #d9d9d9;
    font-size:13px;
}
.credit-box h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom:30px;
}

.credit-box h4 {
    font-size:35px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}


.credit-box .form-credit{
    display: flex;
    align-items: center;
}
.credit-box .form-credit input {
    border:1px solid #d9d9d9;    
    background:#fff;
    font-size: 15px;
    padding: 6px 10px; 
    width: 100%;   
}
.credit-box .form-credit button {    
    background-color:#1a73e8;
    color:#fff;
    border:1px solid #1a73e8;    
    padding: 6px 10px;
    font-weight: 600;
    cursor: pointer;
    font-size: 14px;
    min-width: 130px;
}
.credit-box p {
    margin-bottom:30px;
}
.payment-receipt-popup {
    max-width: 700px;
    font-size: 13px;
}
.receipt-box {   
    padding: 20px 30px 30px 30px;
    /* background: #f3f4f4; */
    margin: 10px 0px;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
}
.receipt-box .comp-addr{
    max-width: 400px;
}
.receipt-box .recp-info ul {
    list-style: none;
}
.receipt-box .recp-info ul li{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:5px 0px;
}
.receipt-box .recp-info ul li span{
    margin-left:15px;
    font-weight: 600;
}
.receipt-header {
    padding-bottom:10px;
    border-bottom:1px solid #d9d9d9;
}
.receipt-box .title h4 {
    padding:10px 0px;
    font-weight: bold;
    font-size: 18px;
    border-bottom:1px solid #d9d9d9;
}
.payment-info {
    padding: 20px 10px;
    font-size: 13px;
    line-height: 28px;
}
.payment-info strong {
    text-decoration: underline;
    margin : 0px 10px;
}
.receipt-auth {
    font-size: 14px;
    line-height: 28px;
    margin-top:10px;
}
.receipt-auth p {
    margin:0px;
}
.receipt-auth h5 {
    font-size: 16px;
}

.room-addon-info{    
    padding:0px 0px 20px 0px;
}

.info-box {
    padding: 20px;   
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);    
    cursor: pointer;
    margin-bottom: 20px;
    position: relative;
    border:2px solid var(--border-color);
}
.info-box:hover {
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
}
.info-box::before {
    content: " ";
    position: absolute;
    left: -4px;
    top: -1px;
    width: 10px;
    height: 75px;
    background: var(--main-button-color);    
}
.upload-box {
    padding:10px;
    background-color: #f4f4f4;
    font-size: 0.65rem;
    text-align: center;
    border: 2px dashed #d9d9d9;
}
.mx-auto {
    margin:0 auto;
}

.room-luggage {
    margin-bottom:20px;
}
.room-toggle {
    padding:10px; 
    cursor: pointer;
    border:1px solid #e9e9e9;
    font-size:13px;
    border-radius: 5px;
    font-weight: bold;
}
.room-luggage:hover,.room-luggage.active {
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);     
}
.room-luggage.active  .room-toggle{
    color:var(--main-button-color);
    background: #f6f6f6;
    border-radius: 5px 5px 0px 0px;
}
.room-travel-doc {
    display: none;    
    padding:10px;
    border:1px solid #e9e9e9;
}
.room-luggage.active .room-travel-doc {
    display: block;
}
.room-img-header{
    width: 60%;
    margin-bottom: 15px;
}
.room-img-header img {
    float: left;
    width: 20px;
    height: 20px;
    background: #555;
  }
  
.room-img-header h4 {
    position: relative;
    font-size: 12px;
    top: 6px;
    left: 10px;
}
.img-header{
    width: 60%;
}
.img-header img {
    float: left;
    width: 30px;
    height: 30px;
    background: #555;
  }
  
.img-header h4 {
    position: relative;
    top: 10px;
    left: 10px;
}
.bill-info-wrap .inner-box {
    max-width: 1024px;
}
.bill-info-wrap h3{
    font-size:1.25em;
}
.price-summery-box dl {
    padding: 20px 0 0 20px;
}
.addons-options .t-item {
    display: none;
}
.t-item.active {
    display: flex !important;
}
.w-45 {
    width:47% !important;
}

.addons-options dl {
    flex-direction: row !important;
}
.addons-options dd {
    margin: 0 20px 20px 0;
    padding: 10px 10px 10px 20px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    width: calc(25% - 20px);
    border-radius: 5px;
    background: #fff;
    position: relative;
    min-height: 100px;
    display: flex;
    flex-direction: column;
}
.room-details-heading ul {
    margin: 10px 0 0;
}
.bu-sm {
    font-size: 0.9em;
    padding: 8px 14px;
}
.bu-light {
    background-color: #fff;
    border:1px solid #1a73e8;
    color: #1a73e8;
}
.bu-light:hover {
    box-shadow: 0px 0 200px rgba(0, 0, 0, 0.15) inset;
}
.contact-details ul{
    margin:10px 0;
    padding:0;
    list-style:none;
}
.contact-details ul li {
    display:flex;
    align-items:center;
    justify-content : space-between;
    font-size:13px;
    padding:6px 0px;
}
.contact-and-room-details .guest dl {
    padding-left:0;
    padding-top:0;
}
.contact-and-room-details .guest dd {
    border-top:none;
}
.contact-and-room-details h4 a {
    font-weight: normal;
}

.labelStyle{
    padding-left: 5px;
    font-size: 13px;
}


.radio-group{
    display:flex
}
.bu {
    background: #1a73e8 !important;
    color: #fff;
    font-weight: 600;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    min-width: 100px;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
}
.bu.light {
    border: 1px solid var(--main-button-color);
    background: #fff;
    color: var(--main-button-color);
}

.bu:hover {
    box-shadow: 0px 0 200px rgba(0, 0, 0, 0.25) inset;
}
.bu.light:hover {
    box-shadow: 0px 0 200px rgba(0, 0, 0, 0.05) inset;
}
.carousel-item img {
    max-width: 100%;
    height: 145px;
    border-radius: 0.5rem !important;
    margin-bottom: 1.5rem !important;
}

.form-holder .social-links a {
    margin: 5px;
    padding: 12px 5px 8px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 15px;
}
.bu:hover {
    box-shadow: inset 0 0 200px rgba(0,0,0,.25); 
    color: #fff;   
}

.bu-fb {
    background-color: #3b5999 !important;
}
.bu-gp {
    background-color: #6db1ea !important;
}
.bu-wc {
    background-color: #1cd24d !important;
}



@media (max-width : 767px) {
    .auth_container .main-container {
        background-color: #fff;
        padding: 0;
    }
    .auth_container .main-container .form-box {   
        max-width: 100%;     
        border-radius: 0px;
        box-shadow:none;
    }
    .auth_container .main-container .img-holder {
        display: none;
    }
    .auth_container .main-container .form-holder {
        flex: 0 0 100%;
        max-width: 100%;
    }
}